import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";
import { useFormStatus } from "react-dom";
const ButtonHeader = forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<"button">>(function ButtonBase({
  className = "",
  ...props
}, ref) {
  const {
    pending
  } = useFormStatus();
  return <button {...props} ref={ref} disabled={props.disabled ?? pending} className={`${className} inline-flex items-center justify-center rounded-md border border-transparent border-violet-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:text-violet-300`} />;
});
export default ButtonHeader;